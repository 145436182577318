header {
    width: 100%;
}

header > div {
    overflow: hidden;
    width: 96%;
    max-width: var(--main-max-width);
    margin: 0 auto 0 auto;
}

header > div > div {
    display: inline-block;
}

.headerLogo {
    margin: 0;
    padding: 0;
    width: 400px;
    height: auto;
}

.burgerIcon {
    display: none;
}

.headerImage {
    width: 100%;
    min-height: 300px;
    height: auto;
}

.headerMenu {
    float: right;
    width: calc(100% - 400px);
}

.headerMenu > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.headerMenu > ul > li {
    float: left;
}

.headerMenu > ul > li a {
    display: block;
    color: black;
    text-align: center;
    padding: 16px;
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: none;
}

.headerMenu > ul > li a:hover {
    color: white;
}

.headerImageContainer {
    width: 100%;
    max-width: unset;
}

.headerInfoContainer {
    position: absolute;
    display: block;
    width: 100%;
    margin: 0 auto;
}

.headerInfo {
    color: var(--main-background-color);
    width: 600px;
    margin-top: 50px;
    left: 10px;
    padding: 20px 30px;
    line-height: 26px;
    font-size: 22px;
    background-color: rgba(255,255,255,0.90);
}

@media only screen and (max-width: 400px) {
    .headerLogo {
        margin-top: 5px;
        width: 60%;
    }

    .headerMenu {
        width: 40%;
        /*display: none;*/
    }

    .headerInfo {
        width: 80%;
    }

    .burgerIcon {
        display: block;
        height: 52px;
        float: right;
    }

    .burgerIcon:hover {
        cursor: pointer;
        opacity: 0.6;
    }
}