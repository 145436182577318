footer {
    width: 100%;
    background-color: var(--main-background-color);
    color: var(--main-text-color);
    padding: 20px 0;
    text-align: center;
    margin: 0 auto 0 auto;
}

footer > p > a {
    text-decoration: none;
    color: var(--main-text-color);
}